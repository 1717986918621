<template>
  <div>
    <b-row class="mb-32">
      <b-col
        cols="12"
        class="mb-32"
      >
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col
            cols="12"
            md="6"
          >
            <h1 class="hp-mb-0">
              Finance
            </h1>
          </b-col>
          <b-col class="hp-flex-none w-auto">
            <!--          <dropdown-range-time :options="options" :selected="selected"/>-->
            <b-dropdown
              v-model="selected"
              text="Pilih Informasi"
              variant="white"
              class="dropdown"
              @change="handleDropdownChange"
            >
              <b-dropdown-item
                v-for="option in options"
                :key="option.id"
                :value="option.value"
                :to="option.to"
                class="dropdown-item"
              >
                {{ option.label }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>

          <b-col class="hp-flex-none w-auto hp-dashboard-line px-0">
            <div
              class="hp-bg-black-40 hp-bg-dark-80 h-100 mx-24"
              style="width: 1px"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex">
          <b-col cols="12">
            <!--  Finance Card -->
            <b-row class="mb-3">
              <b-col cols="12">
                <b-card>
                  <!-- <b-card class="hp-card-4 hp-chart-text-color"></b-card> -->
                  <div class="overflow-hidden">
                    <apexchart
                      type="area"
                      height="350"
                      legend="legend"
                      :options="optionsChart"
                      :series="series"
                    />
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="mb-3"
              >
                <b-card>
                  <b-card-header>
                    <p class="h5 text-center">
                      Top 5 Partik
                    </p>
                  </b-card-header>
                  <b-card-body>
                    <b-table
                      class="custom-table"
                      responsive
                      bordered
                      :fields="fieldsPartik"
                      :items="itemsPartik"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mb-3"
              >
                <b-card>
                  <b-card-header>
                    <p class="h5 text-center">
                      Top 5 BPJS
                    </p>
                  </b-card-header>
                  <b-card-body>
                    <b-table
                      class="custom-table"
                      responsive
                      bordered
                      :fields="fieldsPartik"
                      :items="itemsPartik"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="mb-3"
              >
                <b-card>
                  <b-card-header>
                    <p class="h5 text-center">
                      Top 5 Permintaan Darah
                    </p>
                  </b-card-header>
                  <b-card-body>
                    <b-table
                      class="custom-table"
                      responsive
                      bordered
                      :fields="fieldsPartik"
                      :items="itemsPartik"
                    />
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BDropdown, BDropdownItem, BRow, BCard, BTable,
  BCardBody,
  BCardHeader,
} from 'bootstrap-vue'

export default {
  name: 'DashboardsFinances',
  components: {
    BCol, BDropdown, BDropdownItem, BRow, BCard, BTable, BCardBody, BCardHeader,
  },
  data() {
    return {
      selected: '',
      options: [
        { value: 'analytics', label: 'Informasi Umum', to: '/dashboards/analytics' },
        { value: 'stocks', label: 'Informasi Stok Darah', to: '/dashboards/stocks' },
        { value: 'finances', label: 'Informasi Keuangan', to: '/dashboards/finances' },
      ],
      fieldsPartik: [
        {
          label: 'No',
          key: 'no',
          sortable: true,
        },
        {
          label: 'RS',
          key: 'rs',
          sortable: true,
        },
        {
          label: 'Perubahan',
          key: 'perubahan',
          sortable: true,
        },
        {
          label: 'Jumlah',
          key: 'jumlah',
          sortable: true,
        },
      ],
      itemsPartik: [
        {
          no: '1',
          rs: 'RS. OTISTA',
          perubahan: '2%',
          jumlah: 'Rp.1.080.000',
        },
        {
          no: '2',
          rs: 'RS. OTISTA',
          perubahan: '2%',
          jumlah: 'Rp.1.080.000',
        },
        {
          no: '3',
          rs: 'RS. OTISTA',
          perubahan: '2%',
          jumlah: 'Rp.1.080.000',
        },
        {
          no: '4',
          rs: '',
          perubahan: '',
          jumlah: '',
        },
        {
          no: '5',
          rs: 'RS. OTISTA',
          perubahan: '2%',
          jumlah: 'Rp.1.080.000',
        },
      ],

      series: [
        {
          name: '2024',
          data: [8245, 14452, 8545, 14452, 6012, 223, 224, 23422, 232, 323, 22, 222],
        },
        {
          name: '2023',
          data: [1223, 211],
        },
      ],
      optionsChart: {
        chart: {
          fontFamily: 'Manrope, sans-serif',
          type: 'area',
          id: 'visiters-line-card',

          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#0063F7', '#FF8B9A'],
        labels: {
          style: {
            fontSize: '14px',
          },
        },
        fill: {
          opacity: 0.3,
        },

        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: '#DFE6E9',
          row: {
            opacity: 0.5,
          },
        },
        markers: {
          strokeWidth: 0,
          size: 0,
          colors: ['rgba(0, 255, 198, 0.17)', 'rgba(45, 125, 239, 0.17)'],
          hover: {
            sizeOffset: 1,
          },
        },
        xaxis: {
          axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          // tickPlacement: "between",
          labels: {
            style: {
              colors: ['636E72'],
              fontSize: '14px',
            },
          },
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Juli', 'Augt', 'Sept', 'Octo', 'Dec'],
        },
        legend: {
          horizontalAlign: 'center',
          offsetX: 40,
          position: 'top',
        },
        yaxis: {
          labels: {
            style: {
              colors: ['636E72'],
              fontSize: '14px',
            },
            formatter: value => `${value / 1000}K`,
          },
        },
      },
    }
  },
  methods: {
    handleDropdownChange() {
      const selectedRoute = this.options.find(option => option.value === this.selected).to
      this.$router.push(selectedRoute)
    },
  },
}
</script>

<style scoped>
.custom-table {
  font-size: 14px;
  line-height: 1;
  text-align:center;
}
.dropdown {
  font-size: 14px;
  background-color: white;
  width: 180px;
}
.dropdown-item {
  font-size: 14px;
  width: 167px;
  padding:7px
  }
</style>
